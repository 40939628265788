import { useQuery } from "@tanstack/react-query";
import { contactPhoneNumbersWithTypesAndCapsQuery } from "../queries/contacts";
import { useContacts } from "./useContacts";

export function useContactPhoneNumbersWithTypesAndCaps(
  contactId: string | undefined
) {
  const contacts = useContacts();

  return useQuery(
    contactPhoneNumbersWithTypesAndCapsQuery(
      contacts?.find((c) => c.id === contactId)
    )
  );
}
